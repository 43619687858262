@import 'src/Themes/utils';
@import 'src/Themes/media-query';
@import 'src/Themes/Constants/fonts.scss';
@import 'src/Themes/Constants/colors.scss';

.fixed-header {
  height: 67px;
  background: $white;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
  background-color: $green3;
  .header {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 20px;
    justify-content: flex-start;
    &--text {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      li {
        padding: 0 20px;
      }
    }

    @media (max-width: 600px) {
      width: auto;
    }
  }

  .overviewLink {
    white-space: nowrap;
    color: $white;
    font-weight: 700;
  }

  .overviewLink:hover {
    transform: scale(0.9);
  }

  .mr-20 {
    margin-right: 20px;
  }
  .fixed-header__items {
    height: 67px;
    display: flex;
    align-items: center;
    &--logo {
      content: url(../../../Images/Keiken/white-logo.svg);
      width: 120px;
      margin-right: 100px;
      @media (max-width: 768px) {
        margin-right: 10px;
      }
    }
    &--menus {
      font-size: 16px;
      font-weight: 500;
      list-style-type: none;
      padding: 0;
      display: flex;
      justify-content: flex-start !important;
      margin: 0;
      align-items: center;
      & a {
        margin-right: 20px;
      }
      @media (max-width: 450px) {
        display: flex;
        align-content: space-around;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-top: 15px;
        height: 85px;
      }
      &--logout {
        @media (max-width: 450px) {
          margin-left: 45px;
        }
      }
      &--headquarters {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        &--text {
          color: $white;
          position: relative;
          width: max-content;
          padding: 0 4px;
          cursor: pointer;
          user-select: none;
        }
        @media (max-width: 450px) {
          margin-left: 45px;
        }
        &--dropdown {
          width: max-content;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 30px;
          z-index: 500;
          background-color: transparent;
          align-items: center;
          font-family: 'Muller';
          font-size: 14px;
          &--clip-path {
            position: relative;
            display: flex;
            height: 10px;
            width: 13px;
            top: 1px;
            &--left {
              width: 1px;
              left: 7px;
              position: relative;
              transform: rotate(-150deg);
              box-shadow: 1px 2px 10px 2px #00000038;
            }
            &--middle {
              background-color: white;
              width: 100%;
              clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            }
            &--right {
              right: 7px;
              position: relative;
              width: 1px;
              box-shadow: -1px 2px 10px 2px #00000038;
              transform: rotate(150deg);
            }
          }
          &__card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 160px;
            padding: 16px 8px;
            background-color: $white-three;
            box-shadow: 1px 1px 10px 2px #00000038;
            border-radius: 8px;
            &--title {
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-bottom: 8px;
              padding: 0 8px 8px;
              border-bottom: 1px solid $light-gray-border;
              font-weight: 400;
            }
            &--title.true {
              color: $green3;
              font-weight: 700;
            }

            .logout-button {
              display: flex;
              flex-direction: row;
              align-items: center;
              color: $slate;
              font-weight: 700;
              line-height: initial;
              svg {
                margin-right: 5px;
                g {
                  path {
                    fill: $slate;
                  }
                }
              }
            }
            .logout-button:hover {
              color: $slate;
            }
          }
        }
      }
    }
  }
}

.fixed-header-container.active .fixed-header {
  background-color: white;
  box-shadow: 0 1px 2px 0 blue;
  .shareholder-fixed-header__items {
    &--menu {
      border-bottom: none;
    }
  }
}

.fixed-header {
  z-index: 1;
}

.partner-register-select-image-button-background-outer-class-name {
  width: 530px;
  height: 75px;
  border-radius: 75px;
  background: linear-gradient(to left, #00c87f, #b3ff00);
  position: relative;
  @include responsive(s) {
    width: 100%;
  }
}
