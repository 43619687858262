@import 'src/Themes/utils';
@import '../src/Themes/media-query';
@import './Themes/Constants/fonts.scss';
@import './Themes/Constants/colors.scss';

/**************************************/

/* APP GENERAL */

/**************************************/

* {
  outline: unset !important;
}

.Toaster__manager-bottom {
  bottom: 8px !important;
}

.button:hover {
  transform: scale(0.9);
}

.Toaster__message-wrapper {
  margin-bottom: 12px !important;
  height: 50px !important;
  padding: 3px;
  border-radius: 16px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !important;
  background-image: linear-gradient(
    52deg,
    #f84073 21%,
    #f67d40 62%,
    #fecf44 100%
  ) !important;
  display: flex !important;
  align-items: center !important;
  .Toaster__alert_text {
    font-family: Raleway !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: 0.45px !important;
    text-align: center !important;
    color: #ffffff !important;
  }
}

.Toaster__alert_close {
  color: #ffffff !important;
  font-family: Raleway !important;
  font-size: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  padding-right: 8px !important;
  padding-top: 2px !important;
}

.Toaster__alert {
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.App-routes-wrapper {
  display: flex;
  flex: 1;
  margin-top: 0;
  background: #f9f9fa;
  flex-direction: column;
  .App-routes-wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: 'Raleway';
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  // min-height: 100vh;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.justity-content-center {
  justify-content: center;
}

.align-content-center {
  align-content: center;
}

.form {
  .MuiFormControl-root {
    margin-top: 25px;
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.disable-copy {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/**************************************/

/* DASHBOARD */

/**************************************/

.step-container {
  margin-top: -28px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  @include responsive(s) {
    display: none;
  }
}

.space-step-blank {
  width: 70px;
  height: 1px;
}

.register-mobile-edit-label {
  margin-top: 5px;
  margin-right: 10px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: $pumpkin;
}

.space-step-on {
  width: 90px;
  height: 1px;
  border: solid 1px #1a3c47;
}

.space-step-off {
  width: 90px;
  height: 1px;
  border: solid 1px #b9b9b9;
}

.text-step {
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  color: #1a3c47;
  @include responsive(s) {
    font-size: 1rem;
  }
}

.head-step {
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  width: 140px;
  @include responsive(s) {
    position: unset;
    align-items: flex-start;
    width: 17%;
  }
}

.head-step-text {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 7px;
  position: relative;
  display: flex;
  align-items: center;
  width: 152px;
  @include responsive(s) {
    position: unset;
    justify-content: flex-start;
    width: 100%;
  }
  .disabled {
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    color: #b9b9b9;
  }
}

.progress-step {
  width: 25px;
  height: 25px;
  content: url('./Images/Icons/step-progress.svg');
}

.progress-finish {
  width: 25px;
  height: 25px;
  content: url('./Images/Icons/progress-finish.svg');
}

.progress-none {
  width: 13px;
  height: 13px;
  content: url('./Images/Icons/progress-none.svg');
}

.next-svg {
  width: 17px;
  height: 17px;
  margin-top: auto;
  margin-left: 10px;
  content: url('./Images/Icons/next-white.svg');
}

.back-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
  content: url('./Images/Icons/voltar-gray.svg');
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(226deg) brightness(104%) contrast(101%);
}

.advance-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
  content: url('./Images/Icons/avancar-white.svg');
}

.send-svg {
  width: 40px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  content: url('./Images/Icons/send.svg');
}

.row {
  margin: 0px;
  width: 100%;
  min-width: 200px;
  justify-content: center;
}

.step-container-mobile {
  display: none;
  @include responsive(s) {
    display: flex;
    margin-right: -7px;
    margin-left: -7px;
    border: solid 1px $ice-blue;
    padding: 18px 22px 18px 22px;
  }
}

.space__between {
  justify-content: space-between;
}

.flex__start {
  justify-content: flex-start;
}
/**************************************/

/* FREE REGISTER */

/**************************************/

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #5ab0ae !important;
}

.terms {
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.27px;
  color: #707070;
  @include responsive(s) {
    font-family: Raleway;
  }
}

.terms-link {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.27px;
  color: #ef7d00;
  @include responsive(s) {
    font-family: Raleway;
  }
}

.message-box {
  display: flex;
  width: 488px;
  height: 64px;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.27px;
  color: #4a4a4a;
  vertical-align: middle;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  margin-top: 10px;
  @include responsive(s) {
    width: 100%;
    height: 100%;
    margin-left: 22px;
    margin-right: 22px;
  }
}

.success-back {
  margin-top: 30px;
  position: relative;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  background-color: #fafafa;
}

.label-radio {
  margin-top: 30px;
  margin-bottom: -10px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #1a3c47;
}

.radio-options {
  display: flex;
  @include responsive(s) {
    align-items: center;
  }
}

.main-text {
  margin-top: 45px;
  margin-bottom: 25px;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1a3c47;
  @include responsive(s) {
    font-size: 2.5rem;
    font-family: AccordAlt;
    margin-bottom: unset;
  }
}

.main-text-mobile {
  display: none;
  @include responsive(s) {
    display: flex;
    font-size: 1.375rem;
    width: 80%;
    margin: auto;
    padding-top: 30px;
  }
}

.sub-text {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  @include responsive(s) {
    width: 85%;
  }
}

.sub-text-mobile {
  @include responsive(s) {
    font-size: 1.125rem;
    margin-top: 5px;
  }
}

.register-container {
  align-items: center;
  align-content: center;
  text-align: center;
  margin: auto;
  width: 50%;
  @include responsive(s) {
    width: 100vw;
    padding-left: 7px;
    padding-right: 7px;
  }
}

.blank-header {
  min-height: 80px;
  width: 100vw;
  border: solid 1px #e1e2e6;
  background-color: #ffffff;
  @include responsive(s) {
    display: flex;
    align-items: center;
    position: unset;
    justify-content: center;
  }
}

.header-logo {
  cursor: pointer;
  position: relative;
  margin-top: 20px;
  margin-left: 15%;
  margin-bottom: 20px;
  width: 35px;
  height: 45px;
  content: url('./Images/Icons/logo@3x.png');
  @include responsive(s) {
    position: unset;
    width: 30px;
    height: 38px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
}

/**************************************/

/* LOGIN */

/**************************************/

.page-container {
  height: 100vh;

  @include responsive(s) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}

.full-height {
  height: 100%;
}

.mobile-arrow {
  display: none;
  @include responsive(s) {
    display: flex;
    content: url('../src/Images/Icons/avancar-white.svg');
    align-items: center;
  }
}

.login-container {
  height: 60vh;
  width: 317px;
  max-width: 100%;
  margin-top: 30vh;
  margin-left: 86px;
  @include responsive(s) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin: initial;
    margin-bottom: 37%;
    padding-left: 7px;
    padding-right: 7px;
  }
}

.right {
  float: right;
  margin-left: auto;
}

.left {
  float: left;
  margin-right: auto;
}

.container-banner {
  margin: auto;
  width: 410px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  @include responsive(s) {
    display: flex;
    align-items: center;
    position: unset;
  }
}

.title-banner {
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ffffff;
  @include responsive(s) {
    display: none;
  }
}

.description-banner {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  @include responsive(s) {
    display: none;
  }
}

.link-orange {
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #ef7d00 !important;
  display: flex;
  align-items: center;
}

.link-sm {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #707070;
  cursor: pointer;
  text-decoration: underline !important;
}

.title-login-mobile {
  display: none;
  @include responsive(s) {
    display: inline-block;
    text-align: center;
    padding-bottom: 40px;
    width: 90%;
    margin: auto;
    p {
      font-size: 2.5rem;
      font-weight: 500;
      font-family: AccordAlt;
      margin-bottom: -10px;
    }
    span {
      font-size: 1rem;
      line-height: 1.38;
    }
  }
}

.button-container {
  margin-top: 50px;
  @include responsive(s) {
    margin-top: 35px;
    margin-bottom: 40px;
  }
}

.centered-column {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.button-primary {
  border-radius: 25.5px;
  height: 51px;
  border: none;
  width: 118px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #ffffff;
  background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
  margin-left: 20px;
  @include responsive(s) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin-left: 0px;
  }
}

.button-primary:hover {
  border-radius: 25.5px;
  height: 51px;
  border: none;
  width: 118px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #ffffff;
  background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
  opacity: 0.7;
  @include responsive(s) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
  }
}

.button-second {
  width: 151px;
  height: 51px;
  border-radius: 28px;
  border: solid 1px #a4a4a4;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #a4a4a4;
  margin-right: 20px;
  @include responsive(s) {
    display: none !important;
  }
}

.button-primary:hover {
  border-radius: 25.5px;
  background-color: #ff8400;
  height: 51px;
  border: #ff8400;
  min-width: 115px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #ffffff;
}

.logo {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 5vh;
  width: 35px;
  height: 45px;
  content: url('./Images/Icons/logo@3x.png');
  @include responsive(s) {
    width: 30px;
    height: 38px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
}

.button-container-login {
  margin-top: 50px;
  @include responsive(s) {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    a {
      display: none;
    }
  }
}

.simple-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.simple-col {
  display: flex;
  flex-direction: column;
}

.line-through {
  text-decoration: line-through;
}

.base-full-height {
  margin-top: 10%;
  height: 90%;
}

.clawse-centered-column {
  height: 100vh;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.base-access-denied-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include imageSize(200px, 200px);
  img {
    cursor: pointer;
    margin-bottom: 40px;
    transition: 0.2s ease-in-out;
    &:hover {
      transform: scale(1.5);
    }
  }
  span {
    font-size: 20px;
  }
}
